.how-it-helps-wrapper-div {
    position: relative;
    top: 50px;
    bottom: 0%;
    right: 0%;
    left: 0%;
    width:100%;
    height:100%;
    background-size: 100% 100%;
    color: black;
    background-color: #ecf4f3;
}

.how-it-helps-title {
    color: #002366;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 4vw;
    bottom: 0%;
    right: 0%;
    left: 0%;
}