.cancer-wrapper-div {
    position: absolute;
    color: #000000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 4vw;
    top: 50px;
    bottom: 0%;
    right: 0%;
    left: 0%;
    margin-bottom: 10;
}

.cancer-title {
    color: #02af50;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 5vw;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cancer-paragraph {
    color: #000000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 1.5vw;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cancer-bullet {
    color: #000000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    margin-left: 10%;
    margin-right: 5%;
    font-size: 1.5vw;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cancer-subtitle {
    color: #4472c4;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    margin-left: 0%;
    margin-right: 0%;
    font-size: 3vw;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cancer-sub-subtitle {
    color: #000000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    margin-left: 5%;
    margin-right: 0%;
    font-size: 2vw;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cancer-sub-subtitle-inner {
    color: #4472c4;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    font-size: 2vw;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cancer-paragraph-inner {
    color: #000000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    font-size: 1.5vw;
    margin-top: 10px;
    margin-bottom: 10px;
}

.cancer-sub-subtitle-alt {
    color: #87b95b;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    margin-left: 5%;
    margin-right: 0%;
    font-size: 2vw;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cancer-sub-subtitle-alt2 {
    color: #000000;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    margin-left: 5%;
    margin-right: 0%;
    font-size: 2vw;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cancer-sub-subtitle-alt3 {
    color: #4472c4;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    margin-left: 5%;
    margin-right: 0%;
    font-size: 2vw;
    margin-top: 15px;
    margin-bottom: 15px;
}

.cancer-paragraph-muted {
    color: #737373;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
    font-size: 1.5vw;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 15px;
    margin-bottom: 15px; 
}