.home {
    position: absolute;
    top:50px;
    bottom:0%;
    right:0%;
    left:0%;
    width:100%;
    height:100%;
    background-image: url('blue-sky-clear-sky-cold-fog.jpg');
    background-repeat:no-repeat;
    background-size: 100% 100%;
    background-color: #ecf4f3;
}

.home-title {
    color: #ffffff;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 12vw;
    bottom: 0%;
    right: 0%;
    left: 0%;
}

.home-subtitle {
    position: relative;
    color: #ffffff;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; 
    font-size: 5vw;
    margin-right: auto;
    margin-left: auto;
    top: 5px;
}

.home-subtitle-dark {
    position: relative;
    color: #013220;
    font-size: 4vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-right: auto;
    margin-left: auto;
    padding-top: 2%;
    width: 100%;
    background-color: #ffffff;
    padding-bottom: 2%;
    margin-bottom:2%;
}

.home-wrapper-div {
    display:flex;
    flex-direction: column;
    position: absolute;
    background-color: #ecf4f3;
    top: 0%;
    bottom: 0%;
    right: 0%;
    left: 0%;
    overflow-y: scroll;
}

.home-health-plan {
    position: relative;
    display: flex;
    background-color: #ecf4f3;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.home-health-plan-title {
    color: black;
    font-size: 8vw;
    margin-top:5%;
    margin-bottom:5%;
}

.home-health-plan-detail-row {
    display: flex;
    flex-direction: row;
    flex-grow: 0%;
    width: 100%;
}

.home-health-plan-detail-tab {
    display: flex;
    width: 30%;
    margin-right: auto;
    margin-left: auto;
    flex-direction: column;
    background-color: #efece2;
    margin-bottom: 20px;
}

.home-health-plan-detail-image {
    width: 20vw;
    height: 20vw;
    top: 10%;
    margin-top: 10%;
    margin-bottom: 10%;
    margin-right: auto;
    margin-left: auto;
}

.home-health-plan-detail-text {
    margin-left: 5%;
    margin-right: 5%;
    text-align: justify;
}