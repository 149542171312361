.blog-wrapper-div {
    position: absolute;
    top: 50px;
    bottom: 0%;
    right: 0%;
    left: 0%;
    color: black;
    background-color: #ecf4f3;
    display: flex;
    flex-direction: column;
}